import React, { useState } from "react";
import Menu from "./Menu";

const Categories = ({ categories, filteredMenu }) => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  return (
    <div className="btn-container">
      {categories.map((category) => (
        <button
          key={category}
          type="button"
          onClick={() => {
            setSelectedCategory(category);
            filteredMenu(category);
          }}
          className={
            selectedCategory === category
              ? "filter-btn highlight"
              : "filter-btn"
          }
        >
          {category}
        </button>
      ))}
    </div>
  );
};

export default Categories;
