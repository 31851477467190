import React, { useState } from "react";
import menu from "./data";
import Categories from "./Components/Categories";
import Menu from "./Components/Menu";
const allCategories = ["All", ...new Set(menu.map((menu) => menu.category))];
function App() {
  const [allMenus, setAllMenus] = useState(menu);
  const [categories, setCategories] = useState(allCategories);
  const filteredMenu = (category) => {
    if (category === "All") {
      setAllMenus(menu);
      return;
    }
    let filterdItem = menu.filter((theMenu) => theMenu.category === category);

    setAllMenus(filterdItem);
  };
  return (
    <main>
      <section className="menu section">
        <div className="title">
          <h2>our menu</h2>
          <div className="underline"></div>
        </div>

        <Categories categories={categories} filteredMenu={filteredMenu} />

        <Menu allMenu={allMenus} />
      </section>
    </main>
  );
}

export default App;
